@media screen and (max-width: 1025px) {
    .header-title {
        top: 87px
    }

    // .category-item {
    //     top: calc(112px + 75px) !important;
    // }

    .category-item {
        top: 0;
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        z-index: 9;

        .mobile-menu-list {
            padding: 0;

            &>ul {
                position: absolute;
                width: 100%;
                top: 60px;
                padding: 0;
                left: 0;
                height: 90vh;
                overflow: auto;
                margin-top: 5px;
                // max-width: 73%;
            }
        }

        .header-mobile {
            margin: 0;
        }
    }
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .mobile-menu {
        .mobile-menu-list {
            &>ul {
                max-width: 82% !important;
            }
        }
    }
}

@media screen and (min-width: 1550px) {
    .mobile-menu {
        .mobile-menu-list {
            &>ul {
                max-width: 1130px !important;
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .header-title {
        top: 111px
    }

    // .category-item {
    //     top: calc(111px + 76px) !important;
    // }

    .mobile-menu {
        background: inherit !important;

        .mobile-menu-list {
            position: relative;

            &>ul {
                padding: 0;
                top: 110px;
                left: 0;
                right: 0;
                margin: auto;
                position: fixed;
                width: 100%;
                z-index: 99999;
                max-height: 732px;
                overflow: auto;
                height: auto;
                max-width: 73%;
            }
        }
    }
}

.empty-menu-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.mobile-menu {
    .mobile-menu-list {
        &>ul {
            // max-height: 560px;
            overflow: auto;
            height: auto;
        }
    }
}

.mobile-menu-list {
    .MuiMenuItem-root:hover {
        background-color: #FFF5EA;
        color: #000;
    }

    ul>li.Mui-selected {
        background-color: #FFF5EA;

        &:hover {
            background-color: #FFF5EA;
        }

        >div>div {
            color: #000000
        }
    }
}

.main-tab-category {
    margin-top: 12px;

    a {
        // margin-right: 16px;
        // margin-left: 16px;
        padding: 30px;
        width: 133px;
        height: auto;
        justify-content: flex-start;

        p {
            line-height: 22.4px;
            margin-left: 25px;
        }
    }

    // .light-mode { 
    //     a.Mui-selected {
    //         background: url(../assets/icons/hoverTabIcon.svg) no-repeat 3px 17px;
    //     }
    // }

    .dark-mode {
        a {
            overflow: inherit !important;

            >div {
                margin-left: 25px;
            }
        }

        a.Mui-selected {
            >span {
                display: none;
            }

            >div {
                // margin-left: 25px;
                // margin-right: 10px;
                border-radius: 100%;

            }

            img {
                box-shadow: 0px 0px 16px #A79B9BB5;
                border-radius: 100%;

                >div {
                    border-radius: 100%;
                }
            }
        }
    }

    .light-mode {
        a {
            overflow: inherit !important;

            >div {
                margin-left: 25px;
            }
        }

        a.Mui-selected {
            >span {
                display: none;
            }

            >div {
                // margin-left: 25px;
                // margin-right: 10px;
                border-radius: 100%;
            }

            img {
                box-shadow: 0px 3px 16px #36363652;
                border-radius: 100%;
            }
        }
    }

    // .dark-mode { 
    //     a.Mui-selected {
    //         background: url(../assets/icons/hoverTabIconDark.svg) no-repeat 3px 17px;
    //     }
    // }

}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
}

.linesStyle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}