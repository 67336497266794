// Swiper Style.
header {
  // position: sticky;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 997;
  // background-color: #ffffff;
}

#menu {

  // position: sticky;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 997;
  // background-color: #ffffff;
  align-items: center;

  // .MuiTab-root {
  //   max-height: 35px;
  //   min-height: auto;
  //   border-radius: 1000px;

  //   >p::first-letter {
  //     text-transform: uppercase;
  //   }

  //   p {
  //     text-transform: lowercase;
  //   }
  // }
}

.swiper-category {
  overflow-y: auto;

  .swiper-slide {
    width: auto !important;
  }

  .categoryItem {
    float: left;
  }

  ul {
    white-space: nowrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 62px;
    width: 100%;
    overflow-x: auto;
  }

  li {
    margin-right: 16px;
    padding: 4px 8px;
    cursor: pointer;
  }

  .Mui-selected {
    background: black;

    p {
      color: #fff;
    }
  }

  a,
  button {
    color: black;
    text-decoration: none;
    text-align: center;
    padding: 4px 8px !important;
    border-radius: 5px;
    background: rgb(203 203 203 / 22%);
    margin: 0 10px;
  }

  .MuiTab-root {
    min-width: inherit;

    p {
      padding: 4px 8px;
    }
  }

  .active {
    text-decoration: none;
    background: black;
    border-radius: 1000px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 5px;
  }

  .active a,
  .active button {
    background: none;
  }

  .active p {
    color: #ffffff;
  }

  .activeDark {
    text-decoration: none;
    background: black;
    border-radius: 1000px;
    color: #ffffff;
    padding: 4px 8px;
  }
}

.nav-sidemenu {
  margin-right: 16px;
}

#menu::-webkit-scrollbar {
  display: none;
}

@media all and (max-width: 1024px) {
  .banner-header {
    top: 88px;
  }

  .app-header .navbar {
    height: 84px !important;
  }

  .brand-logo {
    width: 84px !important;
    height: 84px !important;
    max-height: 84px !important;
    max-width: 84px !important;

  }

  .app-header {
    .navbar-brand {
      .title {
        line-height: 22.4px !important;
        margin-top: 8px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        max-width: 202px;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 65px;
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .app-header {
    .navbar-brand {
      .title {
        line-height: 30.8px !important;
        margin-top: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        max-width: 403px;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 60px;
      }
    }
  }
}

@media all and (max-width: 425px) {
  #menu {
    .MuiTabs-hideScrollbar {
      display: flex;
      align-items: center;
    }

    button {
      margin: 0 5px;
      max-height: 35px;
      padding: 3px 5px !important;

      &>p {
        font-size: 0.8rem
      }
    }
  }
}