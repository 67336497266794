@media screen and (max-width: 1025px) {
html {
    scrollbar-width: none;
    box-sizing: content-box;
}
body::-webkit-scrollbar, html::-webkit-scrollbar, #root::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}

div[role="presentation"]::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
}
body, html, #root {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}
div[role="presentation"] {
    .MuiPaper-root::-webkit-scrollbar, .MuiDialogContent-root::-webkit-scrollbar, .product-main::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
    }
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
    .mobile-slider-empty {
        margin: 0 18px !important;
    }
    .each-slide {
        max-width: 480px;
        max-height: 480px;
        margin: auto;
    }
    .content-mobile-product-main {
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin-top: 10px !important;
    }


    .product-list-card {
        min-height: auto !important;
        > div:first-child > p:nth-child(2){
            margin-bottom: 30px !important;
            margin-right: 10px;
        }

        .product-image {
            display: flex;
            align-items: center;
        }
    }
    .product-image {
        width: 112px !important;
        height: 112px !important;
    }
    .contact-info, .product-main {
        // max-width: 100% !important;
        // position: fixed;
        // width: 100% !important;
        // height: 100vh;
        &:focus {
            outline: none;
        }
        img {
            display: flex;
            margin: auto;
        }
    }

    .product-main {
        // overflow: auto;
        .mobile-footer-main {
            position: sticky;
            bottom: 0;
            padding: 0;
            .mobile-footer {
                padding: 19px 0 !important;
            }
        }
    }


    .header-mobile {
        display: flex;
        // background: #F2F2F2;
        padding: 9px 0;
        margin-bottom: 20px;
        .header-mobile-icon {
            margin-left: 9px;
            width: 48px;
            height: 48px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg { 
                font-size: 28px;
            }
        }
    }
    .header-desktop {
        display: none;
    }

    .header-mobile-title {
        padding: 0 20px;
        margin-bottom: 23px;
    }

    .header-mobile-content {
        padding-left: 16px;
        padding-bottom: 5px;
        margin-bottom: 16px !important;
        h6 { 
            font-size: 16px;
        }
    }
    .mobile-footer {
        // position: fixed;
        // width: 100%;
        // bottom: 0;
        min-height: 20px;
    }

    .mobile-footer-main {
        display: flex;
        justify-content: center;
        .mobile-footer {
            // background: #F2F2F2;
            padding: 19.5px;
            > p {
                // color: #333;
                font-size: 14px;
            }
        }
        .desktop-logo-footer {
            display: none;
        }

        .mobile-logo-footer {
            display: block;
        }

    }
    .mobile-logo-footer {
        display: none;
        width: 65px !important;
    }

    .mobile-slider  {
        max-width: 100% !important;
        padding: 0 18px;
        margin: auto;
        img {
            border-radius: 4px;
        }
    }

    .content-mobile-product {
        font-family: "Roboto, sans-serif" !important;
        // text-align: center;
        padding: 0 20px;
        p {
            margin-bottom: 10px;
            // padding: 5px;
        }
    }

    .phone-field {
        input {
            font-size: 16px !important;
            opacity: 1;
            font-family: "Noto Sans Buhid, sans-serif";
        }
    }
}

@media screen and (min-width: 1025px) {
    // .product-main {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     max-width: 100%;
    //     width: 80% !important;
    //     box-shadow: 24;
    //     padding: 24px;
    //     border-radius: 5px;
    //     &:focus {
    //         outline: none;
    //     }
    // }
    // .contact-info {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     max-width: 100%;
    //     width: 80%;
    //     box-shadow: 24;
    //     padding: 24px;
    //     border-radius: 5px;
    //     &:focus {
    //       outline: none;
    //     }
    // }

    .header-desktop {
        display: flex,
    }
    .header-mobile, .mobile-footer-main {
        display: none;
    }
    .header-mobile-title {
        display: none;
    }
    .desktop-logo-footer {
        display: block;
    }

    .mobile-logo-footer {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .mobile-footer {
        // position: fixed;
        // width: 100%;
        // bottom: 0;
        min-height: 20px;
    }

    .product-main {
        max-width: 988px !important;
    }
    .contact-info {
        max-width: 544px !important;
    }
    .mobile-slider  {
        margin: auto;
        img {
            border-radius: 4px;
        }
    }
    .product-list-card {
        & > div:last-child {
            display: flex;
            > div {
                display: flex;
                align-items: center;
            }
        }
        & > div:first-child {
            display: flex;
            padding: 8px 0;
            > p:nth-child(2) {
                display: flex;
                align-items: center;
            }
        }
    }

}


@media screen and (min-width: 768px) and (max-width: 1025px){
    .product-main {
        .content-mobile-product-main {
            margin-top: 10px;
        }
    }

}


@media screen and (max-width: 480px) {
    .mobile-slider-empty {
        width: 320px !important;
        height: auto !important;
        min-height: 320px;
        max-height: 320px;
        img {
            width: 80px;
        }
    }
}

.header-mobile-content:last-child {
    word-break: break-all;
}

.phone-field {
    .special-label{
        display: none;
    }
    input {
        border: none;
        font-size: 20px;
        opacity: 1;
        background: transparent;
        &:focus {
            outline: none;
        }
    }
}

.header-mobile-content {
    padding-right: 16px;
}

.mobile-slider-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 480px;
    height: 480px;
    max-width: 100%;
    background: #eee;
    border-radius: 5px;
    flex-basis: inherit !important;
}

.phone-field-dark {
    input {
        width: 100%;
        color: #fff !important;
        border: none;
        font-size: 20px;
        background: transparent;
        margin: 0;
        font-family: "Noto Sans Buhid", sans-serif;
        line-height: 24px;
    }
}

.phone-field-light {
    input {
        width: 100%;
        color: #000 !important;
        border: none;
        font-size: 20px;
        background: transparent;
        margin: 0;
        line-height: 24px;
    }
}
.phone-field {
    input {
        font-family: "Noto Sans Buhid, sans-serif";
    }
}