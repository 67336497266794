@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap");
@import "variables";
@import "./modal.scss";
@import "./menu.scss";
@import "./navigationMenu.scss";

body {
  margin: 0;
  height: 100vh;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

#root {
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
}

* {
  font-family: "Montserrat", sans-serif;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  position: relative;
  border: 1px solid #8e8e93;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  width: 10px !important;
  height: 10px !important;
  background: transparent !important;
  position: relative;
  border: 1px solid #8e8e93;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator.active:before {
  border: 1px solid #ff8a00;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator.active:after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background: #ff8a00;
  text-align: center;
  top: 1px;
  left: 1px;
}

.react-slideshow-container + ul.indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin-top: 10px !important;
}

.react-slideshow-container {
  display: flex;
  align-items: center;
  position: relative;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator {
  opacity: 1 !important;
}

.react-slideshow-container + ul.indicators li {
  width: 6px;
  height: 6px;
}

.circular-progress {
  width: 100%;
  position: fixed;
  z-index: 9999;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  img {
    width: 150px;
  }
}

@media all and (max-width: 900px) {
  .mobile-footer {
    img {
      width: 70px !important;
    }
    a,
    p {
      font-size: 14px !important;
    }
  }
  .MuiTabs-scrollButtons {
    &:first-child {
      // left: -90px;
      left: -42px;
    }
    &:last-child {
      // right: -50px;
      right: 4px;
    }
  }
}

@media all and (min-width: 900px) {
  .MuiTabs-scrollButtons {
    &:first-child {
      // left: -90px;
      left: -48px;
    }
    &:last-child {
      // right: -50px;
      right: -11px;
    }
  }
}

.main-tab-category {
  .MuiTypography-root {
    text-transform: none;
  }

  .MuiTabs-scrollButtons {
    z-index: 1;
  }
}

.MuiTabs-scrollButtons {
  margin: 0 10px !important;
  position: absolute !important;
  z-index: 9;
  transform: translate(50%, 50%);
  top: 50%;
  bottom: 50%;

  svg {
    width: 2em;
    height: 2em;
    border-radius: 100px;
    color: #ff8a00;
    padding: 7px;
    font-size: 12px;
  }
}

.dark-mode {
  .MuiTabs-scrollButtons {
    svg {
      background: #48484a;
    }
    display: flex !important;
  }
}

.light-mode {
  .MuiTabs-scrollButtons {
    svg {
      background: #ffffff;
      opacity: 0.9;
    }
    display: flex !important;
  }
}

.wrapper {
  line-break: auto;
}

#read-more {
  // color: #000000;
  text-decoration: underline;
  cursor: pointer;
  margin: 4px 10px;
  font-weight: 600;
  font-family: "Noto Sans Buhid", sans-serif;
}

html {
  scrollbar-width: none;
  box-sizing: content-box;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}

div[role="presentation"]::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
body,
html,
#root {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
div[role="presentation"] {
  .MuiPaper-root::-webkit-scrollbar,
  .MuiDialogContent-root::-webkit-scrollbar,
  .product-main::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

@media all and (max-width: 1024px) {
  * {
    cursor: default !important;
  }
}

@media all and (max-width: 426px) {
  .mobile-footer {
    > div {
      > div {
        flex-direction: column;
        text-align: center;
        justify-content: center;
        > div {
          margin-top: 10px;
        }
      }
    }
  }
  .each-slide {
    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: 480px;
      aspect-ratio: 1;
    }
  }
}

.MuiCircularProgress-colorPrimary {
  color: #ff8901 !important;
}
